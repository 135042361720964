<template>
  <v-row class="pb-8">
    <v-col>
      <carousel />
      <session-title
        class="pt-8 px-8 pb-0"
        title="Elogios Recebidos"
      />
      <compliment-cards />
    </v-col>
  </v-row>
</template>

<script>
import SessionTitle from '../../components/SessionTitle.vue'
import ComplimentCards from '../../components/ComplimentCards.vue'
import Carousel from "@/components/Carousel";


export default {
  name: 'ListCompliment',
  components: {
    carousel: Carousel,
    'session-title': SessionTitle,
    'compliment-cards': ComplimentCards
  }
}
</script>

<style></style>
