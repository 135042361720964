<template>
  <v-container fluid>
    <v-row
      v-if="compliment.length > 0"
      align="center"
      justify="start"
      class="px-12"
    >
      <v-col
        v-for="item in compliment"
        :key="item.id"
        cols="12"
        md="3"
        sm="6"
      >
        <v-hover
          v-slot="{ hover }"
          open-delay="20"
        >
          <v-card
            height="275"
            :elevation="hover ? 16 : 0"
            color="indigo lighten-5"
            class="mx-auto d-flex flex-column justify-space-between"
          >
            <v-card-subtitle class="text-uppercase pt-3 px-6 pb-0">
              {{ item.createdAt | dateFormatted }}
            </v-card-subtitle>
            <v-card-text class="py-0 px-2 text-card mt-0 ">
              <v-card-title
                style="word-break: break-word"
                class="pt-2"
              >
                Apreciação feita por: {{ item.user || nameFormatted }}
              </v-card-title>
              <v-card-subtitle class="pb-0">
                Para: Você
              </v-card-subtitle>
              <v-card-text class="text-justify pb-0">
                Mensagem: {{ item.text }}
              </v-card-text>
              <v-row class="py-0 px-6 pb-1">
                <v-rating
                  :value="5"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="20"
                />
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row
      v-else
      align="center"
      justify="center"
      class="px-12"
    >
      <v-col
        cols="12"
        class="text-center"
      >
        <h3 class="text--disabled">
          Não há nenhum elogio por enquanto 😔.
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ComplimentSerice from '../services/compliment-service'
export default {
  name: 'ComplimentCards',
  props: { max: { type: Number, default: () => 0 } },
  data: () => ({
    compliment: [],
    user: ''
  }),
  async created () {
    try {
      this.user = this.$store.getters['auth/userName']
      const data = await ComplimentSerice.findComplimentByUser(this.user)
      this.compliment = data
      if (this.max > 0) {
        this.compliment = this.compliment.slice(0, this.max)
      }
    } catch (e) {
      this.$handleHttpError(e)
    }
  }
}
</script>
